import { useEffect, useState } from "react";
import 'katex/dist/katex.min.css'
import Latex from 'react-latex-next'
function McqQuestionSpecial({ question, answeredIndex = -1,subjectId, subjectIndex = 0,index, handleSubmitMcqSpecial }) {
  const [counter, NULL] = useState(index + 1);
  const [options, setOptions] = useState(question.options);
  const [answered,setAnswered] = useState(false);
  const [answeredOption,setAnsweredOption] = useState(-1);
  const answeredLabel = " bg-orange-500	 text-4xl";
  
  useEffect(() => {
    if (question.type === false) {
      setOptions(Array.from(Array(question.optionCount)).map((e, i) => String.fromCharCode(65 + parseInt(i))));
    }
  }, [question]);
  const changeBg =(e, index,subjectId,subjectIndex) =>{
    setAnswered(true);
    setAnsweredOption(parseInt(e.target.value));
    console.log(parseInt(e.target.value));
    handleSubmitMcqSpecial(e, index,subjectId,subjectIndex)
  }
  // const change = e =>{
  //   e.target.style.backgroundColor ="#F54B03";
  // }
  return (
    <div className="mb-6 relative">
      <span className="questionNo">{counter}</span>
      <div className="mb-3 rounded-lg shadow-[0px_0px_6px_2px_rgba(0,0,0,0.75)]">
        {question.type === false ? (
          <div>
            <img className="rounded-lg" alt="img" src={`${process.env.REACT_APP_FILES_HOST}/${question.question}`} />
          </div>
        ) : (
          <span>
            <Latex>{question.question}</Latex>
          </span>
        )}
      </div>
      <div className="grid grid-cols-1 grid-flow-row gap-x-8 gap-y-2">
        {/* {
          options.map((opt, idx) => (
            <div className="odd:justify-self-end even:justify-items-start" key={`opt.${idx}`}>
              <label className={`custom-label cursor-pointer inline-flex items-center relative rounded-lg py-1 px-12 ${answeredIndex === idx &&  "text-lg bg-['#F54B03']"} `} 
                onClick={(e)=>changeBg(e)}
              >
                <input
                  onChange={(e) => handleSubmitMcqSpecial(e, index,subjectId,subjectIndex)}
                  type="radio"
                  className={`custom-radio`}
                  value={idx}
                  checked={answeredIndex === idx}
                  disabled={parseInt(answeredIndex) !== -1 && answeredIndex !== idx}
                />
                <span className="absolute left-[50%] -translate-x-[50%]"> {opt}</span>
              </label>
            </div>
          ))
        } */}
        {question.type === false ? (<div className="grid grid-cols-2 grid-flow-row gap-x-8 gap-y-2">
        {
          options.map((opt, idx) => (
            <div className="odd:justify-self-end even:justify-items-start" key={`opt.${idx}`}>
              <label className={` c-label cursor-pointer inline-flex items-center relative rounded-lg py-1 px-12  ${idx===answeredOption ? 'bg-[#F54B03]' : 'bg-[#eaeaeaff]'} ` }
              
              >
                <input
                  onChange={(e) => changeBg(e, index,subjectId,subjectIndex)}
                  // onChange={(e) => handleSubmitMcqSpecial(e, index,subjectId,subjectIndex)}
                  type="radio"
                  className="custom-radio "
                  value={idx} 
                  checked={parseInt(answeredIndex) === idx}
                  disabled={parseInt(answeredIndex) !== -1 && answeredIndex !== idx}
                  
                />
                <span className="absolute left-[50%] -translate-x-[50%]"> {opt}</span>
              </label>
            </div>
          ))
        }
      </div>) : (
        <div className="grid grid-cols-1 justify-items-start gap-x-8 gap-y-5">
          {
            options.map((opt, idx) => (
              <>
              {/* <div className="md:hidden"></div> */}
              <div className="" key={`opt.${idx}`}>
                <label className="custom-label text-lg cursor-pointer inline-flex relative rounded-lg py-1 px-2">
                  <input
                    onChange={(e) => handleSubmitMcqSpecial(e, index,subjectId,subjectIndex)}
                    type="radio"
                    className="custom-radio custom-radio2"
                    value={idx}
                    checked={answeredIndex === idx}
                    disabled={parseInt(answeredIndex) !== -1 && answeredIndex !== idx}
                  />
                  <span className="absolute left-[15px] top-[3px] font-bold">
                    {String.fromCharCode(65 + parseInt(idx))}</span><Latex>{opt}</Latex>
                </label>
              </div>
              {/* <div className="md:hidden"></div> */}
            </>))
          }
        </div>
      )}
      </div>
    </div>
  );
}

export default McqQuestionSpecial;
