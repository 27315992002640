// import { PhotoIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import axios from '../utils/axios'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-hot-toast'

function EditProfile() {
  const [studentInfo, setStudentInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState('')
  const [dpAvailable,setDpAvaialbe] = useState(false);
  const [pp,setPp] = useState(null);
  function handleChange(e) {
    console.log(e.target.files)
    console.log(e.target.files[0].size)
    if (e.target.files[0].size > 30000) {
      toast.error('Image size cannot be more than 20kb')      
    } else {
      setPp(e.target.files[0])
      setFile(URL.createObjectURL(e.target.files[0]))
    }
  }
  const handleUpdateStudent =(e)=>{
    e.preventDefault();
    let form = e.target;
    let name = form.name.value;
    let institution = form.institution.value;
    let mobileNo = form.mobileNo.value;
    let curriculumRoll = form.curriculumRoll.value;


    const formdata = new FormData();
    let displayPicture =null;
    console.log("aaa",form);
    
    if(pp){
      console.log(pp);
      formdata.append("dp",pp)
    }else{
      console.log(pp);
      formdata.append("dp",displayPicture);
    }
   
    formdata.append("name",name);
    formdata.append("institution",institution);
    formdata.append("mobileNo",mobileNo);
    formdata.append("curriculumRoll",curriculumRoll);
    setIsLoading(true)
    axios.post('/api/student/editstudent',formdata,{
      headers: {
        "Content-Type": "multipart/ form-data",
      },
    })
    .then(({data})=>{
      // console.log(data);
      toast.success("Profile Changes Updated");
      axios.get(`/api/student/getstudentinfobyid`).then(({ data }) => {
        console.log(data)
        if(data.displayPicture===null){
          setFile(data.displayPicture);
        }else{
          setFile(data.displayPicture);
          setDpAvaialbe(true);
        }
        setStudentInfo(data)
        setIsLoading(false)
      })
    })
  }

  useEffect(() => {
    setIsLoading(true)
    axios.get(`/api/student/getstudentinfobyid`).then(({ data }) => {
      // console.log(data)
      if(data.displayPicture===null){
        setFile('');
      }else{
        setDpAvaialbe(true);
      }
      setStudentInfo(data)
      setIsLoading(false)
    })
  }, [])
  return (
    <div className="px-2 lg:px-60 py-10  ">
      {isLoading && (
        <Skeleton
          count={20}
          height={8}
          baseColor="#FF4B0025"
          highlightColor="#FF4B00"
          className="mt-8"
        ></Skeleton>
      )}
      <form className="bg-white px-4 lg:px-20 py-10 rounded-xl"  onSubmit={handleUpdateStudent}>
        <div className="space-y-12 ">
          <div className="border-b border-gray-900/10 pb-12 ">
            <div className="flex justify-center items-center">
              <h2 className=" font-extrabold text-2xl lg:text-4xl leading-7 text-color-one">
                Edit Profile
              </h2>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-xl lg:text-3xl font-medium leading-6 text-color-one"
                >
                  Profile photo
                </label>
                <div className="mt-2 flex-col lg:flex-row justify-center lg:justify-between rounded-lg border border-dashed border-color-one px-6 py-10">
                  {
                    dpAvailable? <div className='flex justify-center items-center'>
                    <img className='w-48 h-48' src={`${process.env.REACT_APP_FILES_HOST}/${studentInfo.displayPicture}`} alt="pp" />
                  </div> 
                  : <div>
                    {file !== '' ? (
                      <div className='flex justify-center items-center'>
                        <img className='w-48 h-48' src={file} alt="pp" />
                      </div>
                    ) : (
                      <div className="w-48 h-48 bg-gray-300 mx-auto rounded-full shadow-2xl my-1 flex items-center justify-center text-indigo-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-24 w-24"
                          viewBox="0 0 20 20"
                          fill="#F54B03"
                        >
                          {' '}
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          />
                        </svg>{' '}
                      </div>
                    )}
                  </div>
                  }
                  <div className="flex justify-center items-center">
                    {
                      !dpAvailable && <div>
                      <div className="mt-4 flex text-xl leading-6 text-gray-600">
                        <label
                          htmlFor="dp"
                          className="relative cursor-pointer rounded-md bg-white font-bold text-2xl text-color-one focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="dp"
                            name="dp"
                            type="file"
                            className="file-input w-full input-bordered  border-color-one "
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <p className="mt-3 text-xl text-center font-extrabold leading-5 text-red-600">
                        PNG, JPG up to 20kb
                      </p>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-gray-900/10 pb-12">
              <div className="flex justify-center items-center mt-5">
                <h2 className=" font-extrabold text-xl lg:text-3xl leading-7 text-color-one">
                  Personal Information
                </h2>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-xl font-medium leading-6 text-color-one"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      //   autoComplete="given-name"
                      defaultValue={studentInfo.name}
                      className="block w-full rounded-md border-gray-400 border-2 text-center py-1.5 text-color-one shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xl sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="institution"
                    className="block text-xl font-medium leading-6 text-color-one"
                  >
                    Institution
                  </label>
                  <div className="mt-2">
                    <input
                      id="institution"
                      name="institution"
                      type="institution"
                      defaultValue={studentInfo.institution}
                      className="block w-full rounded-md  py-1.5 border-gray-400 border-2 text-center text-color-one shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xl sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="mobileNo"
                    className="block text-xl font-medium leading-6 text-color-one"
                  >
                    Mobile Number
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="mobileNo"
                      id="mobileNo"
                      defaultValue={studentInfo.mobileNo}
                      className="block w-full rounded-md border-gray-400 border-2 text-center py-1.5 text-color-one shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xl sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="curriculumRoll"
                    className="block text-xl font-medium leading-6 text-color-one"
                  >
                    Curriculum Roll
                  </label>
                  <div className="mt-2">
                    <input
                      id="curriculumRoll"
                      name="curriculumRoll"
                      type="curriculumRoll"
                      defaultValue={studentInfo.curriculumRoll}
                      className="block w-full rounded-md  py-1.5 border-gray-400 border-2 text-center text-color-one shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xl sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-color-one px-3 py-2 font-bold text-2xl text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  )
}
export default EditProfile
