import React, { useState } from 'react'
import axios from '../utils/axios'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

function ChangePassword() {
  const [prevPassword, setPrevPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [message, setMessage] = useState('')
  const [textColor, setTextColor] = useState('text-green-500')
  const [isLoading, setIsLoading] = useState(false)
  const [prevShow, setPrevShow] = useState(true)
  const [newBar, setNewBar] = useState(false)
  const navigate = useNavigate();

  const checkPrevPassword = () => {
    setIsLoading(true)
    axios
      .get('/api/student/checkpassword?password=' + prevPassword)
      .then(({ data }) => {
        if (data.check === true) {
          setMessage('')
          setIsLoading(false)
          setTextColor('text-green-500')
          setPrevShow(false)
          setNewBar(true)
        } else {
          setMessage('Please Provide Correct Password')
          setTextColor('text-red-500')
          setIsLoading(false)
        }
      })
  }
  const updateNewPassword = () => {
    if(newPassword.length<6){
      setMessage("Password must be 6 characters long");
      setTextColor('text-red-500');
      document.getElementById("new_password").value = '';
      document.getElementById("confirm_new_password").value = '';
      return;
    }
    if (newPassword === confirmNewPassword ) {
      setMessage('');
      
      setIsLoading(true)
      axios
        .post('/api/student/changepassword?password=' + newPassword)
        .then(({ data }) => {
          toast.success("Password Updated Successfully");
          localStorage.clear()
          navigate('/');

        })
    } else {
      document.getElementById("new_password").value = '';
      document.getElementById("confirm_new_password").value = '';
      setMessage('New and Confirm Password must be Same')
      setTextColor('text-red-700 text-xl my-3')
    }
  }
  return (
    <div className="px-2">
      <div className="px-2 lg:px-10  flex justify-center items-center my-20">
        {prevShow && (
          <div className="py-4 w-full lg:w-1/3 border border-color-one rounded-lg px-2 lg:px-4 mt-10">
            <label className="font-bold text-center  text-lg">
              <span className="label__text text-color-one">
                Current Password{' '}
              </span>
            </label>
            <input
              type="password"
              placeholder="Enter Current Password"
              className="input input-bordered rounded-lg w-full my-4 font-bold"
              onChange={(event) => setPrevPassword(event.target.value)}
            />
            <span className={`font-bold ${textColor}`}>{message}</span><br/>
            <button
              className="btn text-white bg-color-one px-10"
              onClick={() => checkPrevPassword()}
            >
              Check
            </button>
          </div>
        )}

        {newBar && isLoading!==true && (
          <div className="grid grid-cols-1 gap-4 mt-10  ">
            <p className="text-2xl font-bold text-red-500 mt-5 text-center">
              Password must contains minimum of 6 characters
            </p>
            <div className="grid grid-cols-1 gap-4 border-2 rounded-lg border-color-one mb-10 ">
              <div className="flex justify-center items-center">
                <div className=" w-full lg:w-1/2 px-2 lg:px-4 mt-2 ">
                  <label className="font-bold text-center  text-lg">
                    <span className="label__text text-color-one">
                      New Password{' '}
                    </span>
                  </label>
                  <input
                    type="password"
                    id="new_password"
                    placeholder="Enter New Password"
                    className="input input-bordered rounded-lg w-full my-1 font-bold"
                    onChange={(event) => setNewPassword(event.target.value)}
                  />
                </div>
              </div>

              <div className="flex justify-center items-center">
                <div className=" w-full lg:w-1/2 border  px-2 lg:px-4 mt-2 ">
                  <label className="font-bold text-center  text-lg">
                    <span className="label__text text-color-one">
                      Confirm New Password{' '}
                    </span>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter Confirm Password"
                    id="confirm_new_password"
                    className="input input-bordered rounded-lg w-full my-1 font-bold"
                    onChange={(event) =>
                      setConfirmNewPassword(event.target.value)
                    }
                  />
                  <span className={`font-bold ${textColor}`}>{message}</span>
                  <div className="flex justify-center items-center">
                    <button
                      className="btn text-white bg-color-one px-10 mb-4 "
                      onClick={() => updateNewPassword()}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <div
            aria-label="Loading..."
            role="status"
            className="flex items-center space-x-2"
          >
            <svg
              className="h-20 w-20 animate-spin stroke-color-one"
              viewBox="0 0 256 256"
            >
              <line
                x1="128"
                y1="32"
                x2="128"
                y2="64"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="195.9"
                y1="60.1"
                x2="173.3"
                y2="82.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="224"
                y1="128"
                x2="192"
                y2="128"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="195.9"
                y1="195.9"
                x2="173.3"
                y2="173.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="128"
                y1="224"
                x2="128"
                y2="192"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="60.1"
                y1="195.9"
                x2="82.7"
                y2="173.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="32"
                y1="128"
                x2="64"
                y2="128"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="60.1"
                y1="60.1"
                x2="82.7"
                y2="82.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
            </svg>
            <span className="text-4xl font-medium text-color-one">
              Loading...
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ChangePassword
