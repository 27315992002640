import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/stylesheet/custom.css";
import 'react-loading-skeleton/dist/skeleton.css';
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
const variation = {
  1:"mcq",2:"written",3:"mcq-written",4:"special",5:"mcq-special"
};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App  variation={variation}/>
      <Toaster/>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
