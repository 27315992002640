import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";
import authChecker from "../utils/authChecker"
import ExamList from "../components/ExamList";
import backIcon from "../assets/img/icons/leftArrow.png";
import BackButton from "../components/common/BackButton";
const types = {
  daily: 1,
  weekly: 2,
  monthly: 3,
};
const title = {
  daily: "Daily Exam",
  weekly: "Weekly Exam",
  monthly: "Monthly Exam",
};
function AllExams() {
  const params = useParams();
  const [TOKEN, setTOKEN] = useState(null);
  const [list, setList] = useState(null);
  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
    authChecker();
  }, []);

  useEffect(() => {
    if (TOKEN) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;
      if(localStorage.getItem("FORCOURSE")){
        axios.get(`/api/subject/getsubjectbycourse?courseId=${localStorage.getItem("FORCOURSE")}`)
          .then(({ data }) => {
            setList(data);
          }).catch(err => {
            console.log(err);
          });
      }else{
        window.location.href = '/login';
      }
    }
  }, [TOKEN, params.type]);
  return (
    list && (
      <div className="px-28 md:px-4">
        <div className="container mx-auto pt-16">
          <ExamList title={title[params.type]} exam={list.data} exam_slug={params.type} hideViewAll={true} />
        </div>
        <div className="pb-8 max-w-md mx-auto">
          <BackButton title="Back to Home Page" icon={backIcon} />
        </div>
      </div>
    )
  );
}

export default AllExams;
