import { Suspense, lazy, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import axios from '../../utils/axios'
import backIcon from '../../assets/img/icons/leftArrow.png'
import BackButton from '../../components/common/BackButton'
import authChecker from '../../utils/authChecker'
// const ExamInfoDetails = lazy(() => import("../../components/common/ExamInfoDetails"));
const ExamInfoDetails2 = lazy(() =>
  import('../../components/common/v2/ExamInfoDetails')
)

const ExamSolutionWritten = () => {
  const params = useParams()
  const [queryParams] = useState(new URLSearchParams(window.location.search))
  const [homeUrl, setHomeUrl] = useState('/')
  const [TOKEN, setTOKEN] = useState(null)
  const [examData, setExamData] = useState(null)
  const [examDetails, setExamDetails] = useState(null)
  const [writtenNotAttended, setWrittenNotAttended] = useState(null)
  useEffect(() => {
    setTOKEN(localStorage.getItem('STDNTTKN'))
  })
  useEffect(() => {
    if (localStorage.getItem('STDNTTKN')) {
      setHomeUrl('/home')
    }
  }, [])
  useEffect(() => {
    if (TOKEN) {
      authChecker()
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
      if (queryParams.get('type') === 'written') {
        Promise.all([
          axios.get(
            '/api/student/viewSollutionwritten?examId=' + params.exam_code
          ),
          axios.get('/api/exam/getexambyid?examId=' + params.exam_code),
        ])
          .then((res) => {
            // setExamData(res[0].data);
            if (res[0].status !== 202) {
              setExamData(res[0].data)
            } else {
              setWrittenNotAttended('You did not attempt the written exam.')
              setExamData(res[0].data.data1)
            }
            if (!res[1].data) {
              window.alert('Invalid Exam or Exam deactivated')
              window.location.href = '/history'
            }
            console.log(res[1].data)
            setExamDetails(res[1].data)
          })
          .catch((err) => {
            console.log(err)
            window.alert('Something went wrong, please inform us')
          })
      } else if (queryParams.get('type') === 'written-both') {
        Promise.all([
          axios.get(
            '/api/student/bothviewsollutionwritten?examId=' + params.exam_code
          ),
          axios.get('/api/both/getbothexambyid?examId=' + params.exam_code),
        ])
          .then((res) => {
            // setExamData(res[0].data);
            if (res[0].status !== 202) {
              setExamData(res[0].data)
            } else {
              setWrittenNotAttended('You did not attempt the written exam.')
              setExamData(res[0].data.data1)
            }
            if (!res[1].data) {
              window.alert('Invalid Exam or Exam deactivated')
              window.location.href = '/history'
            }
            res[1].data.duration = res[1].data.writtenDuration
            setExamDetails(res[1].data)
          })
          .catch((err) => {
            console.log(err)
            window.alert('Something went wrong, please inform us')
          })
      } else if (queryParams.get('type') === 'written-special') {
        Promise.all([
          axios.get(
            '/api/special/viewsollutionwritten?examId=' + params.exam_code
          ),
          axios.get(
            '/api/special/showspecialexambyidstudent?examId=' + params.exam_code
          ),
        ])
          .then((res) => {
            if (res[0].status !== 202) {
              setExamData(res[0].data)
            } else {
              setWrittenNotAttended('You did not attempt the written exam.')
              setExamData(res[0].data.data1)
            }
            if (!res[1].data) {
              window.alert('Invalid Exam or Exam deactivated')
              window.location.href = '/history'
            }
            setExamDetails(res[1].data)
          })
          .catch((err) => {
            console.log(err)
            window.alert('Something went wrong, please inform us')
          })
      }
    }
  }, [TOKEN, params.exam_code])
  return (
    <>
      <div className="flex flex-row bg-white text-center mb-8">
        <div className="h-[68px] mx-auto">
          <Link to={homeUrl}>
            <img src="/images/logo.png" alt="logo" className="w-64" />
          </Link>
        </div>
      </div>
      <div className="pb-8 px-4 min-h-[90vh]">
        <div className="container max-w-5xl mx-auto">
          {writtenNotAttended && (
            <div className="relative mx-2 md:text-md text-2xl border-2 border-red-400 p-2 rounded-lg text-red-600 font-bold text-center">
              You did not attempt the written exam.
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={4}
                stroke="currentColor"
                className="absolute top-[50%] -translate-y-[50%] -right-[12px] text-white bg-red-400 rounded-full w-6 p-[0.2rem] h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
          {/* examInoDetails */}
          {queryParams.get('type') === 'written' && (
            <div className="mb-8 p-2 ">
              {examDetails ? (
                <Suspense
                  fallback={<Skeleton count={1} height={128}></Skeleton>}
                >
                  <ExamInfoDetails2
                    examInfos={examDetails}
                    variationType="written"
                  />
                </Suspense>
              ) : (
                <Skeleton count={1} height={128}></Skeleton>
              )}

              {examData && (
                <div>
                  <div className="mb-6 rounded-lg p-4 bg-white border-2 border-orange-600">
                    <div className="mb-3">
                      <img
                        className="w-full rounded-b-lg"
                        src={`${process.env.REACT_APP_FILES_HOST}/${examData.question}`}
                      />
                    </div>
                  </div>
                  {!writtenNotAttended && (
                    <h3 className="text-center text-xl font-bold border-b-2 border-t-2 border-orange-400 mt-4 mb-6">
                      Answerscripts
                    </h3>
                  )}
                  <div className="bg-white">
                    {examData?.sollutionScript?.map((value, index) => (
                      <div className="mb-6 pb-1 shadow-[0px_0px_6px_2px_rgba(0,0,0,0.5)] rounded-lg">
                        <div className="flex p-2 justify-between border-b-2 border-border-color-4">
                          <h5 className="text-color-one">
                            Marks:{' '}
                            <strong>
                              {examData.marksPerQuestion[index] ?? 0}
                            </strong>
                          </h5>
                          <div className="mx-auto">|</div>
                          <h5 className="text-color-one">
                            Obtained Marks:{' '}
                            <strong>
                              {examData.obtainedMarks[index] ?? 0}
                            </strong>
                          </h5>
                        </div>
                        {/* <div className="flex p-2 justify-center">
                                                <img src={`${process.env.REACT_APP_FILES_HOST}/${value}`} alt={value} />
                                            </div> */}
                        {value.map((img, idx) => (
                          <div
                            className="relative m-4 rounded-lg flex p-2 justify-center border-2 border-orange-200"
                            key={idx}
                          >
                            <span className="questionNo">{idx + 1}</span>
                            <img
                              src={`${process.env.REACT_APP_FILES_HOST}/${img}`}
                              alt={img}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {queryParams.get('type') === 'written-both' && (
            <div className="mb-8 p-4 bg-white rounded-lg border-2 border-orange-200">
              {examDetails ? (
                <Suspense
                  fallback={<Skeleton count={1} height={128}></Skeleton>}
                >
                  <ExamInfoDetails2
                    examInfos={examDetails}
                    variationType="written"
                  />
                </Suspense>
              ) : (
                <Skeleton count={1} height={128}></Skeleton>
              )}

              {examData && (
                <>
                  <div className="mb-6  rounded-lg shadow-[0px_0px_6px_2px_rgba(0,0,0,0.25)]">
                    <div className="mb-3">
                      <img
                        className="w-full rounded-b-lg"
                        src={`${process.env.REACT_APP_FILES_HOST}/${examData.question}`}
                      />
                    </div>
                  </div>
                  {!writtenNotAttended && (
                    <h3 className="text-center text-xl font-bold border-b-2 border-t-2 border-orange-400 mt-4 mb-6">
                      Answerscripts
                    </h3>
                  )}
                  {examData?.sollutionScript?.map((value, index) => (
                    <div
                      className="mb-2 mt-6 pb-1 shadow-[0px_0px_6px_2px_rgba(0,0,0,0.5)] rounded-lg"
                      key={index}
                    >
                      <div className="flex p-2 justify-between border-b-2 border-border-color-4">
                        <h5 className="text-color-one">
                          Question no: <strong>{parseInt(1 + index)}</strong>
                        </h5>
                        <div className="mx-auto">|</div>
                        <h5 className="text-color-one">
                          Marks:{' '}
                          <strong>
                            {examData.marksPerQuestion[index] ?? 0}
                          </strong>
                        </h5>
                        <div className="mx-auto">|</div>
                        <h5 className="text-color-one">
                          Obtained Marks:{' '}
                          <strong>{examData.obtainedMarks[index] ?? 0}</strong>
                        </h5>
                      </div>
                      {value?.answerScript?.map((img, idx) => (
                        <div
                          className="relative m-4 rounded-lg flex p-2 justify-center border-2 border-orange-200"
                          key={idx}
                        >
                          <span className="questionNo">{idx + 1}</span>
                          <img
                            src={`${process.env.REACT_APP_FILES_HOST}/${img}`}
                            alt={img}
                          />
                        </div>
                      ))}
                      {value?.remark !== null && (
                        <div className="my-4 pl-5 mx-4  py-10 border border-color-one rounded-lg">
                          <h1 className="text-2xl ml-2">
                            {' '}
                            Remarks :{' '}
                            <span className="pl-5 text-color-one">
                              {' '}
                              {value.remark.comment}
                            </span>
                          </h1>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
          {queryParams.get('type') === 'written-special' && (
            <div className="mb-8 p-2">
              {examDetails ? (
                <Suspense
                  fallback={<Skeleton count={1} height={128}></Skeleton>}
                >
                  <ExamInfoDetails2
                    examInfos={examDetails}
                    variationType="special"
                    hide="mcq"
                  />
                </Suspense>
              ) : (
                <Skeleton count={1} height={128}></Skeleton>
              )}
              {examData && (
                <>
                  {examData.map((value, index) => (
                    <div
                      className="mt-6 rounded-lg bg-white border-2 border-orange-600"
                      key={index}
                    >
                      <h2 className="bg-orange-600 text-2xl font-bold text-center text-white mb-0 uppercase">
                        {value.name ?? ''}
                      </h2>
                      <div className="p-4 rounded-b-lg ">
                        <div className="mb-3">
                          <img
                            className="w-full rounded-lg shadow-[0px_0px_6px_2px_rgba(0,0,0,0.25)]"
                            src={`${process.env.REACT_APP_FILES_HOST}/${value.iLink}`}
                          />
                        </div>
                        {!writtenNotAttended && (
                          <h3 className="text-center text-xl font-bold border-b-2 border-t-2 border-orange-400 mt-4 mb-6">
                            Answerscripts
                          </h3>
                        )}
                        {value?.answer?.map((answerS, indexx) => (
                          <div
                            className="mb-4 shadow-[0px_0px_4px_2px_rgba(0,0,0,0.15)] rounded-lg"
                            key={indexx}
                          >
                            <div className="flex py-2 px-6 justify-between border-b-2 border-border-color-4">
                              <h5 className="text-color-one">
                                Question no:{' '}
                                <strong>{parseInt(1 + indexx)}</strong>
                              </h5>
                              <div className="mx-auto">|</div>
                              <h5 className="text-color-one">
                                Obtained Marks:{' '}
                                <strong>
                                  {value.marksPerQuestion[indexx] ?? 0}
                                </strong>
                              </h5>
                            </div>
                            {answerS?.answerScript?.map((iLink, index3) => (
                              <div
                                className="relative flex p-2 justify-center"
                                key={index3}
                              >
                                <span className="questionNo">{index3 + 1}</span>
                                <img
                                  src={`${process.env.REACT_APP_FILES_HOST}/${iLink}`}
                                  alt={iLink}
                                />
                              </div>
                            ))}
                            {answerS?.remark !== null && (
                              <div className="my-2 pl-5 mx-4  py-8 border border-color-one rounded-lg">
                                <div className="mb-4">
                                  <h1 className="text-2xl ml-2 mb-5">
                                    {' '}
                                    Remarks :{' '}
                                    <span className="pl-5 text-color-one">
                                      {' '}
                                      {answerS.remark.comment}
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            )}
                            <div className="h-4"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
          <div className="pb-8 max-w-md mx-auto">
            <BackButton
              url="/history"
              title="Back to History Page"
              icon={backIcon}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ExamSolutionWritten
