import { useState, useEffect, Suspense, lazy } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from "../../utils/axios";

import Skeleton from 'react-loading-skeleton';
import { useParams } from "react-router-dom";
import ExamInfoDetails from "../../components/common/v2/ExamInfoDetails";
import CountDownTwo from "../../components/common/CountDownTwo";
import Question from "../../components/common/Question";
import authChecker from "../../utils/authChecker";
const Modal = lazy(() => import("../../components/common/v2/ResultSummery"));
const Modal2 = lazy(() => import("../../components/common/Modal"));


const types = {
  1: "daily exam",
  2: "weekly exam",
  3: "monthly exam",
};

const RetakeExam = () => {

  const [homeUrl, setHomeUrl] = useState("/");
  const params = useParams();
  const [TOKEN, setTOKEN] = useState(null);
  const [timer, setTimer] = useState(0);
  const [examData, setExamData] = useState(null);
  const [two, setTwo] = useState(null);
  const [examDetails, setExamDetails] = useState();
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
  });
  useEffect(() => {
    if (localStorage.getItem("STDNTTKN")) {
      setHomeUrl("/home");
    }
  }, []);

  useEffect(() => {
    authChecker();
    if (TOKEN) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;

      Promise.all([
        axios.get('/api/student/retake?examId=' + params.exam_code),
        axios.get('/api/exam/getexambyid?examId=' + params.exam_code)
      ]).then(res => {
        let questions = res[0].data.question.map((v) => {
          v.answeredOption = "-1";
          return v;
        })
        setExamData(questions);
        setTwo(res[0].data.two);
        setExamDetails(res[1].data);
        const startDiff = moment().add(res[1].data.duration, 'minutes');
        // if (startDiff > res[1].data.duration) {
        setTimer(startDiff.valueOf());
        // } else {
        // const lastStartDiff = moment().add(res[1].data.duration, 'minutes').diff(moment(res[1].data.endTine), 'minutes');
        // if (lastStartDiff <= res[1].data.duration) {
        //   setTimer(lastStartDiff.valueOf());
        // } else {
        // }
        // setTimer(moment(startTime).add(res[1].data.duration, 'minutes').valueOf());
        // }
      }).catch(err => {
        console.log(err);
        window.alert("Something went wrong, please inform us");
      });

    }
  }, [TOKEN, params.exam_code]);


  const handleQuestionSelect = (event, activeQuestion) => {
    let all = examData;
    all[activeQuestion].answeredOption = event.target.value;
    setExamData([...all]);
  }
  const handleSubmit = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;
    const answerIndex = examData.map((d) => d.answeredOption);
    const qId = examData.map((d) => d.id);
    const formData = {
      examId: params.exam_code,
      qId,
      answerIndex,
      doc: two,
    }
    axios.post('/api/student/retakesubmit', formData)
      .then(({ data }) => {        
        setResult({ bgColor: 'none',result:data, customWidth: 'max-w-4xl', hideMerit: true });
        document.getElementById('my-modal-4').checked = true;
        document.getElementById('my-modal-4').addEventListener('change', (e) => {
          if (e.target.checked === false) {
            window.location.href = '/history';
          }
        });
      }).catch(err => {
        console.log(err);
      })

  }

  return (<>
    <div className="flex flex-row bg-white text-center mb-8">
      <div className='h-[68px] mx-auto'>
        <Link to={homeUrl}>
          <img src="/images/logo.png" alt="logo" className='w-64' />
        </Link>
      </div>
    </div>
    <div className="px-28 md:px-4 pb-12">
      <div className="container mx-auto max-w-4xl">
        {/* examInoDetails */}
        <div className="pb-8 bg-white rounded-xl">
          {examDetails && (<div className="flex btn-theme rounded-t-xl py-1 justify-center">
            <span className="text-2xl font-bold capitalize text-white">{types[examDetails.examType]}</span>
          </div>)
          }
          {timer > 0 && <div className="bg-white sticky top-0 z-50">
            <CountDownTwo date={timer} completedAction={handleSubmit} />
          </div>}
          {examData && <div className="px-6"><ExamInfoDetails examInfos={examDetails} variationType="mcq" /></div>}
          <div className="p-6">
            {examData ? examData.map((question, index) => (
              <Question question={question} index={index} key={index} handleQuestionSelect={handleQuestionSelect} />
            )) : (<Skeleton count={5} height={128}></Skeleton>)}
            {/* submit ans */}
            <div className="text-center my-4">
              <button className="btn-theme text-white px-28 md:px-8 py-3 rounded-md" onClick={handleSubmit}>
                Finish Exam
              </button>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={null}>
        <Modal2 {...error} />
      </Suspense>
      <Suspense fallback={null}>
        <Modal {...result}/>
      </Suspense>
    </div>
  </>
  );
};

export default RetakeExam;
