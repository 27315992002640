import React, { useEffect, useState } from "react";
import authChecker from "../utils/authChecker"
import { Link } from "react-router-dom";
import axios from "../utils/axios"
import Pagination from "../components/common/Pagination";
import Skeleton from 'react-loading-skeleton';
import rulerpen_raw from '../assets/img/icons/rulerpen_raw.svg'


const MissedExams = () => {
  const [TOKEN, setTOKEN] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [apiCallDone, setApiCallDone] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
  });

  useEffect(() => {
    if (TOKEN) {
      authChecker();
      console.log("first")
      getHistoryData(1);
    }
  }, [TOKEN]);


  function getHistoryData(page = 1, type = 0) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
    setApiCallDone(false);
    setActiveTab(type);
    axios.get('/api/student/missedexam?examVariation=' + parseInt(type + 1) + '&page=' + page)
      .then(({ data }) => {
        setTableData(data.resultFinal);
        setPaginationData(data.paginateData);
        setApiCallDone(true);
      }).catch(err => {
        console.log(err);
        setTableData([]);
        setPaginationData([]);
        setApiCallDone(true);
      });
    // if (type === 0) {
    //   setActiveTab(0);
    //   axios.get('/api/student/missedexam?examVariation='+parseInt(type+1)+'&page=' + page)
    //     .then(({ data }) => {
    //       setTableData(data.resultFinal);
    //       setPaginationData(data.paginateData);
    //       setApiCallDone(true);
    //     }).catch(err => {
    //       setTableData([]);
    //       setPaginationData([]);
    //       setApiCallDone(true);
    //     });
    // } else if (parseInt(type) === 1) {
    //   setActiveTab(1);
    //   axios.get('/api/student/missedexam?examVariation='+parseInt(type+1)+'&page=' + page)
    //     .then(({ data }) => {
    //       setTableData(data.resultFinal);
    //       setPaginationData(data.paginateData);
    //       setApiCallDone(true);
    //     }).catch(err => {
    //       setTableData([]);
    //       setPaginationData([]);
    //       setApiCallDone(true);
    //     });
    // }else if(parseInt(type) === 2){
    //   setActiveTab(2);
    //   axios.get('/api/student/missedexam?examVariation='+parseInt(type+1)+'&page=' + page)
    //     .then(({ data }) => {
    //       setTableData(data.resultFinal);
    //       setPaginationData(data.paginateData);
    //       setApiCallDone(true);
    //     }).catch(err => {
    //       setTableData([]);
    //       setPaginationData([]);
    //       setApiCallDone(true);
    //     });
    // }
  }

  const handlePageClick = (event) => {
    let clickedPage = event.selected + 1;
    getHistoryData(clickedPage, activeTab);
  };
  return (
    <div className="px-28 md:px-4">
      <div className="container mx-auto">
        {/* subject */}
        {/* <div className="my-8 flex gap-4 md:gap-2 justify-start ">
          <span className="bg-color-four px-4 md:px-2 py-4 inline-block ">
            Subject:physics
          </span>
          <span className="bg-color-five px-4 py-4 inline-block">
            Course:physics 1
          </span>
        </div> */}

        {/* missed exam table */}
        <div className="overflow-x-auto min-h-[580px]">
          <div className="tabs bg-transparent rounded-t-lg mt-4 mx-auto">
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 0 ? 'tab-active' : ''}`} onClick={() => getHistoryData(1, 0)}>MCQ</a>
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 1 ? 'tab-active' : ''}`} onClick={() => getHistoryData(1, 1)}>Written</a>
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 2 ? 'tab-active' : ''}`} onClick={() => getHistoryData(1, 2)}>Both</a>
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 3 ? 'tab-active' : ''}`} onClick={() => getHistoryData(1, 3)}>Special</a>
          </div>
          {
            apiCallDone ? (
              <table className="overflow-x-scroll table-fixed w-full customTable !mt-0">
                {/* head */}
                <thead>
                  <tr className="text-center">
                    <th className="py-5 w-[80px]">Sl No.</th>
                    <th className="w-[180px]">Date</th>
                    <th className="w-[180px]">Subject</th>
                    <th className="w-[180px]">Name</th>
                    <th className="w-[180px]">Type</th>
                    <th className="w-[180px]">Durartion</th>
                    <th className="w-[180px]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData && tableData.length ?
                    tableData.map((data, index) => (
                      <tr
                        key={index}
                        className="even:bg-table-row-even odd:bg-table-row-odd text-center "
                      >
                        <th className="py-5">{++index}</th>
                        <td>{data.startTime}</td>
                        <td>{data.subject}</td>
                        <td>{data.exanName}</td>
                        <td>{data.examType}</td>
                        <td>{data.duration} Mins</td>
                        {activeTab === 0 && <td>
                          <div className="flex ">
                            <Link
                              to={`/exam/${data.id}/retake`}
                              className="btn-theme rounded-md text-center text-white px-3 py-2"
                            >
                              Retake
                              <img src={rulerpen_raw} alt="rulerpen" className="h-5 w-auto inline pl-2" />
                            </Link>
                            <div className="ml-3">
                            {
                                data.solutionSheet && <Link
                                to={data.solutionSheet} target="_blank" rel="noreferrer"
                                className={"tooltip text-center tooltip-left bg-yellow-400 rounded-full"} data-tip="Solution Sheet">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="p-1 inline-block h-[38px] w-[38px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                  </svg>

                                </Link>
                              }
                            </div>
                          </div>
                        </td>}
                        {activeTab === 1 &&  <td>
                          {
                                data.solutionSheet && <Link
                                to={data.solutionSheet} target="_blank" rel="noreferrer"
                                className={"tooltip text-center tooltip-left bg-yellow-400 rounded-full"} data-tip="Solution Sheet">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="p-1 inline-block h-[38px] w-[38px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                  </svg>

                                </Link>
                              }
                        </td>}
                        {activeTab === 2 && <td>
                          {
                                data.solutionSheet && <Link
                                to={data.solutionSheet} target="_blank" rel="noreferrer"
                                className={"tooltip text-center tooltip-left bg-yellow-400 rounded-full"} data-tip="Solution Sheet">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="p-1 inline-block h-[38px] w-[38px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                  </svg>

                                </Link>
                              }
                        </td>}
                        {activeTab === 3 &&  <td>
                          <Link
                                  to={`/exam/${data.id}/before-start/retake`}
                                  className="tooltip text-center"
                                  data-tip="Retake the exam"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    // className="size-6"
                                     className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>
                                  {/* Retak */}
                                </Link>
                          {
                                data.solutionSheet && <Link
                                to={data.solutionSheet} target="_blank" rel="noreferrer"
                                className={"tooltip text-center tooltip-left bg-yellow-400 rounded-full"} data-tip="Solution Sheet">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="p-1 inline-block h-[38px] w-[38px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                  </svg>

                                </Link>
                              }
                        </td>}
                        {activeTab === 4 &&   <td>
                        
                          {
                            
                                data.solutionSheet && <Link
                                to={data.solutionSheet} target="_blank" rel="noreferrer"
                                className={"tooltip text-center tooltip-left bg-yellow-400 rounded-full"} data-tip="Solution Sheet">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="p-1 inline-block h-[38px] w-[38px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                  </svg>

                                </Link>
                              }
                        </td>}
                        
                      </tr>
                    ))
                    : (<tr><td colSpan={activeTab == 0 ? 7 : 6} className="lg:text-center text-3xl sm:pl-5 py-2 bg-slate-200 text-red-600">No data found</td></tr>)
                  }
                </tbody>
              </table>) : (<Skeleton count={1} height={8} baseColor="#FF4B0025" highlightColor="#FF4B00" className="mt-8"></Skeleton>)
          }
        </div>

        {/* pagination */}
        <div className="pb-8">
          {paginationData && <Pagination pageCount={paginationData.totalPages} currentPage={paginationData.currentPage} handlePageClick={(e) => handlePageClick(e)} />}
        </div>
      </div>
    </div>
  );
};

export default MissedExams;
