import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import Toast from '../Toast'
import Files from 'react-files'
import Loader from '../../Loader'

//used in spcial written

function UploadAnswerSpecialNew({
  index,
  token,
  exam_code,
  subjectId,
  uploadEndpoint = '',
}) {
  const [placeholder] = useState(`${index}  নং প্রশ্নের উত্তর আপলোড করুন`)
  const [message, setMessage] = useState(null)
  const [uploaded, setUploaded] = useState(false)
  const [selectedImages, setSelectedIMages] = useState([])
  const [dataImage, setDataImage] = useState([])
  const [disable, setDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [uploadedImages, setUploadedImages] = useState([])

  async function onFileSelected(newFiles) {
    setIsLoading(true)
    setSelectedIMages((prevFiles) => [...prevFiles, ...newFiles])
    for (let i = 0; i < newFiles.length; i++) {
      let image = newFiles[i]
      let reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = async (e1) => {
        let imageUrl = e1.target.result
        let bigImage = document.createElement('img')
        bigImage.src = imageUrl
        bigImage.onload = async (e2) => {
          let canvas = document.createElement('canvas')
          let ratio = 800 / e2.target.width
          canvas.width = 800
          canvas.height = e2.target.height * ratio

          const context = canvas.getContext('2d')
          context.drawImage(bigImage, 0, 0, canvas.width, canvas.height)
          let newImageUrl = context.canvas.toDataURL('image/jpeg', 80)
          setDataImage((prev) => [...prev, newImageUrl])
        }
      }
    }
  }
  const handleError = (error, file) => {
    setSelectedIMages([])
    window.alert(error.message)
  }
  async function uploadImages(e) {
    e.preventDefault()
    let result = window.confirm('Upload answer script?')
    if (result) {
      const uploadAnswer = {
        examId: exam_code,
        questionNo: index,
        subjectId,
        questionILink: dataImage,
      }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      axios
        .post(uploadEndpoint, uploadAnswer, {
          headers: {
            'Content-Type': 'application/json',
            'X-examid': exam_code,
            examid: exam_code,
          },
        })
        .then(({ data }) => {
          setDisable(true)
          setUploaded(true)
          setUploadedImages(data)
          let res = JSON.parse(localStorage.getItem(`${exam_code}data`))
          if (res && res.length > 0) {
            res.push(`${exam_code}${subjectId}#${index}`)
            localStorage.setItem(`${exam_code}data`, JSON.stringify(res))
          } else {
            let a = []
            a.push(`${exam_code}${subjectId}#${index}`)
            localStorage.setItem(`${exam_code}data`, JSON.stringify(a))
          }
          setMessage({
            message: 'Image uploaded',
            cssClass: 'alert-success',
            position: 'bottom',
            alignment: 'end',
          })
          setTimeout(function () {
            setMessage(null)
          }, 3000)
        })
    } else {
      setMessage({
        message: 'Request cancelled',
        cssClass: 'alert-error',
        position: 'bottom',
        alignment: 'end',
      })
      setTimeout(function () {
        setMessage(null)
      }, 3000)
    }
  }
  useEffect(() => {
    // window.scrollTo(0,0);
    if (
      selectedImages.length === dataImage.length &&
      selectedImages.length > 0
    ) {
      //   alert('Please Click The upload button')
      setDisable(false)
      setIsLoading(false)
    }
    const res = JSON.parse(localStorage.getItem(exam_code + 'data'))

    // console.log(res);
    if (res && res.length > 0) {
      for (let i = 0; i < res.length; i++) {
        if (res[i] === `${exam_code}${subjectId}#${index}`) {
          setIsDone(true)
          break
        }
      }
    }
  }, [selectedImages, dataImage, exam_code, subjectId, index, isDone])
  return (
    <>
      <Toast {...message} />
      {isLoading && <Loader></Loader>}
      <div className="w-full px-3 py-2 rounded-lg shadow-[0px_0px_2px_2px_rgba(275,75,0,0.75)] md:hidden ">
        {placeholder}
      </div>
      <div className="w-full p-1 mb-2 block lg:hidden rounded-lg shadow-[0px_0px_2px_2px_rgba(275,75,0,0.75)]">
        {placeholder}
      </div>
      {isDone ? (
        <span className="text-xl p-4 text-green-700 font-bold">
          You have uploaded this answer successfully
        </span>
      ) : (
        <div className="inline md:block my-4 w-2/3 md:w-full md:border-2 md:p-3 md:rounded-lg">
          <label className="label font-bold md:font-thin relative md:hidden z-10">
            <span className="absolute top-1 left-3 bg-white px-2 text-title-2 tab-max:text-sm">
              Submit Your Answer (Max. 15 Photos)
            </span>
          </label>

          <div className="flex flex-col md:hidden">
            <div className="flex flex-row flex-wrap w-full md:hidden relative">
              {!isLoading && (
                <div className="grow md:w-full border-2 rounded-lg p-3 md:p-6 border-title-2 bg-white text-gray-500 overflow-x-auto">
                  {selectedImages.length > 0 && (
                    <div className="files-list">
                      <ul className="flex ">
                        {selectedImages.map((file) => (
                          <li key={file.id} className="files-list-item">
                            <div className="files-list-item-preview">
                              <img
                                className="inline h-12 w-12 pr-1 files-list-item-preview-image"
                                src={file.preview.url}
                              />
                            </div>
                            <div className="files-list-item-content">
                              {/* <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div> */}
                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                {file.sizeReadable}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {!isLoading && (
                <Files
                  className="files-dropzone-list"
                  dragActiveClassName="files-dropzone-active"
                  onChange={onFileSelected}
                  onError={handleError}
                  accepts={[
                    'image/png',
                    'image/png',
                    'image/jpeg',
                    'image/gif',
                  ]}
                  multiple
                  maxFiles={15}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <button
                    className="bg-gray-300 rounded-full p-2 ml-4 md:hidden "
                    title="ছবি সিলেক্ট কর"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                      />
                    </svg>
                  </button>
                </Files>
              )}
              {!isLoading && (
                <div className=" ml-2 mt-0 grid grid-cols-2  ">
                  <div className="">
                    <button
                      className="bg-title-2 text-white rounded-full p-2 ml-2 md:hidden disabled:bg-orange-300"
                      title="ছবি আপলোড কর"
                      disabled={disable}
                      onClick={(e) => uploadImages(e)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                        />
                      </svg>
                    </button>
                    <div className="flex justify-center items-center">
                      <h1 className="text-sm font-semibold text-red-500 text-center ml-2">
                        upload
                      </h1>
                    </div>
                  </div>
                  <div>
                    <button
                      disabled={uploaded === false}
                      role="div"
                      className="rounded-full md:hidden ml-2 p-2 bg-green-400 disabled:bg-slate-500 text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="float-right mt-2 ml-16">
              {uploadedImages.length === 0 && (
                <h1 className="text-2xl text-red-600 font-bold">
                  প্রশ্নের উত্তর আপলোড কর !{' '}
                </h1>
              )}
            </div>
            <div className="mt-3 mb-1">
              {uploadedImages.length > 0 && (
                <div className="border-2 border-color-one rounded-lg px-10 py-5  grid grid-cols-1 ">
                  <div className="flex justify-center items-center">
                    <h1 className="text-color-one font-bold ">
                      Uploaded Images
                    </h1>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {uploadedImages.length > 0 &&
                      uploadedImages.map((img, id) => (
                        <div className="border border-color-one rounded-lg py-2 px-3 ">
                          <span>{id + 1}</span>
                          <img
                            key={id}
                            src={process.env.REACT_APP_FILES_HOST + '/' + img}
                            alt="uploaded_images"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* for mobile design */}
          <div className="text-center mt-2 block  min-sm:hidden min-sm:w-full">
            {/* <div className="w-full p-1 mb-2 rounded-lg shadow-[0px_0px_2px_2px_rgba(275,75,0,0.75)]">
            {placeholder}
          </div> */}
            <label className="label font-bold md:font-thin relative md:block z-10">
              <span className="absolute top-1 left-3 bg-white px-2 text-title-2 text-sm sm:text-xs">
                Submit Your Answer (Max. 15 Photos)
              </span>
            </label>
            <div className="flex flex-row flex-wrap w-full relative mb-2">
              {!isLoading && (
                <div className="grow md:w-full border-2 rounded-lg p-3 md:p-6 border-title-2 bg-white text-gray-500 overflow-x-auto">
                  {selectedImages.length > 0 && (
                    <div className="files-list">
                      <ul className="flex ">
                        {selectedImages.map((file) => (
                          <li key={file.id} className="files-list-item pr-1">
                            <div className="files-list-item-preview">
                              <img
                                className="inline h-12 w-12 pr-1 files-list-item-preview-image"
                                src={file.preview.url}
                              />
                            </div>
                            <div className="files-list-item-content">
                              {/* <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div> */}
                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                {file.sizeReadable}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {!isLoading && (
                <div className="flex justify-center w-full">
                  <Files
                    className="files-dropzone-list"
                    dragActiveClassName="files-dropzone-active"
                    onChange={onFileSelected}
                    onError={handleError}
                    accepts={[
                      'image/png',
                      'image/png',
                      'image/jpeg',
                      'image/gif',
                    ]}
                    multiple
                    maxFiles={15}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <button
                      className="bg-gray-300 rounded-full mt-1 p-2 ml-2 min-sm:hidden"
                      title="ছবি সিলেক্ট কর"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                        />
                      </svg>
                    </button>
                  </Files>
                  <div className="mt-1 grid grid-cols-2">
                    <div className="ml-2">
                      <button
                        className="bg-title-2 text-white rounded-full p-2 ml-2 min-sm:hidden"
                        title="ছবি আপলোড কর"
                        onClick={(e) => uploadImages(e)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                      </button>
                      <div className="flex justify-center items-center">
                        <h1 className="text-sm font-semibold text-red-500 text-center ml-2">
                          upload
                        </h1>
                      </div>
                    </div>
                    <div>
                      <button
                        disabled={uploaded === false}
                        role="div"
                        className="rounded-full ml-2 p-2 min-sm:hidden bg-green-400 disabled:bg-slate-500 text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={3}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className=" mt-2 ml-4">
                {uploadedImages.length === 0 && (
                  <h1 className="text-2xl text-red-600 font-bold">
                    প্রশ্নের উত্তর আপলোড কর !{' '}
                  </h1>
                )}
              </div>
              <div className="mt-3 mb-1">
                {uploadedImages.length > 0 && (
                  <div className="border-2 border-color-one rounded-lg px-10 py-5  grid grid-cols-1 ">
                    <div className="flex justify-center items-center">
                      <h1 className="text-color-one font-bold ">
                        Uploaded Images
                      </h1>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      {uploadedImages.length > 0 &&
                        uploadedImages.map((img, id) => (
                          <div className="border border-color-one rounded-lg py-2 px-3 ">
                            <span>{id + 1}</span>
                            <img
                              key={id}
                              src={process.env.REACT_APP_FILES_HOST + '/' + img}
                              alt="uploaded_images"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadAnswerSpecialNew
