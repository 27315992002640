import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import cross from '../../../assets/img/icons/cross.svg'
import 'react-circular-progressbar/dist/styles.css'
import { useParams } from 'react-router-dom'

const ResultSummeryMcqCombined = ({
  title = 'Exam Result',
  result,
  bgColor = 'warning',
  customWidth = '',
  hideCloseBtn = false,
}) => {
  const container = []
  const params = useParams()
  let totalmarks = 0 ;
  if (result) {
    console.log(result)
    for (let i = 0; i < result.subjectDetails.length; i++) {
        // totalmarks = totalmarks + result.subjectDetails[i].marksMcqPerSub ;
      container.push(
        <div className="resultSummery_col" key={i}>
          <span className="resultSummery_col_top h-[55%]  ">
            {result.subjectDetails[i].subjectName}
          </span>
          <span className="resultSummery_col_bottom !text-2xl">
            MCQ: {result.subjectDetails[i].subjectMarks}
            <br />
          </span>
          <div className="text-center text-md text-slate-700">
            Obtained Marks
            <br />
            <span className="pl-1 font-bold text-title-2">
              {result.subjectDetails[i].marksMcqPerSub}
            </span>
            <br />
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <input type="checkbox" id="my-modal-8" className="modal-toggle" />
      <div className="modal modal-middle">
        <div
          className={`modal-box rounded-2xl p-0 relative bg-${bgColor} ${customWidth}`}
        >
          <h3 className="text-3xl font-bold py-1 text-center text-white bg-title-2">
            {title}
          </h3>
          {!hideCloseBtn && (
            <label
              htmlFor="my-modal-8"
              className="btn btn-xs btn-circle absolute right-2 top-2"
            >
              <img className="w-3 h-3" alt="aaa" src={cross} />
            </label>
          )}
          {result && (
            <div className="relative md:min-h-[18rem] overflow-auto p-4">
              {result.examName !== '' ? (
                <h2 className="text-3xl font-bold mb-2">
                  {result.examName} ({result.examVariation})
                </h2>
              ) : (
                <h2 className="text-3xl font-bold mb-2">Retake Result</h2>
              )}
              <div
                className={`grid tab:grid-cols-${
                  result.subjectDetails.length + 1
                } grid-flow-rows gap-x-4 tab-max:grid-cols-2 gap-y-4 place-content-center resultSummery sm:grid-cols-1`}
              >
                {container}

                <div className="resultSummery_col_circle tab-max:col-span-2 h-full">
                  <CircularProgressbarWithChildren
                    value={result.totalObtainedMarks}
                    maxValue={result.totalMarks}
                    styles={buildStyles({
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: '#00e500',
                      textColor: '#000',
                      trailColor: '#646464',
                      border: 'none',
                    })}
                  >
                    <div className="marksContainer">
                      <span className="top">{result.totalObtainedMarks}</span>
                      <span className="bottom">{result.totalMarks}</span>
                    </div>
                  </CircularProgressbarWithChildren>
                  <div className="text-3xl">Your Marks</div>
                </div>
              </div>
              <div className="text-center p-2 text-white text-xl">
                <span className="py-1 px-3 btn-theme rounded-s-3xl">
                  Your Merit Position
                </span>
                <span className="py-1 px-3  bg-[#203a4a] rounded-e-3xl">
                  {result.rank === '-1' ? 'Pending' : result.rank}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResultSummeryMcqCombined
