import { Suspense, lazy, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "../../utils/axios";
import BackButton from "../../components/common/BackButton";
import RightArrow from '../../components/common/svg/RightArrow';
import backIcon from "../../assets/img/icons/leftArrow.png";
import authChecker from "../../utils/authChecker";
import CountDownTwo from "../../components/common/CountDownTwo";

const moment = require('moment');
const Toast = lazy(() => import("../../components/common/Toast"));
const Modal = lazy(() => import("../../components/common/Modal"));
const ExamInfoDetails = lazy(() => import("../../components/common/v2/ExamInfoDetails"));

const ExamRules = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [TOKEN, setTOKEN] = useState(null);
  const [examDetails, setExamDetails] = useState();
  const [examRules, setExamRules] = useState();
  const [btnText, setBtnText] = useState("Show Questions");
  const [timer, setTimer] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
  });

  useEffect(() => {
    if (TOKEN) {
      authChecker();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;

      if (params.exam_variation === "mcq" || params.exam_variation === "written") {
        Promise.all([
          axios.get('/api/exam/getexambyid?examId=' + params.exam_code),
          axios.get('/api/exam/examruleget?examId=' + params.exam_code)
        ]).then(res => {
          setExamDetails(res[0].data);
          setExamRules(res[1].data);
        }).catch(err => {
          window.alert("Something went wrong, please inform us");
          console.log(err);
        });
      } else if (params.exam_variation === "mcq-written") {
        Promise.all([
          axios.get('/api/both/getbothexambyid?examId=' + params.exam_code),
          axios.get('/api/both/bothexamruleget?examId=' + params.exam_code)
        ]).then(res => {
          setExamDetails(res[0].data);
          setExamRules(res[1].data);
        }).catch(err => {
          window.alert("Something went wrong, please inform us");
          console.log(err);
        });
      }else if(params.exam_variation === "mcq-special") {
        Promise.all([
          axios.get('/api/mcqspecialexam/showspecialexambyidstudent?examId=' + params.exam_code),
          axios.get('/api/mcqspecialexam/examruleget?examId=' + params.exam_code)
        ]).then(res => {
          setExamDetails(res[0].data);
          setExamRules(res[1].data);
          let maxDuration = moment.utc(res[0].data.data.endTimeMcq).subtract(6, 'h');
          let lastTime = moment(maxDuration);
          setTimer(lastTime.valueOf());
          if (lastTime.isAfter(res[0].data.data.endTimeMcq)) {
            maxDuration = moment.utc(res[0].data.data.endTimeMcq).diff(res[0].data.data.startTimeMcq, "m");
            lastTime = moment(moment.utc(res[0].data.data.endTimeMcq).add(maxDuration, "m"));
            setTimer(lastTime.valueOf());
          }
        }).catch(err => {
          window.alert("Something went wrong, please inform us");
          console.log(err);
        });

      } 
      else {
        Promise.all([
          axios.get('/api/special/showspecialexambyidstudent?examId=' + params.exam_code),
          axios.get('/api/special/examruleget?examId=' + params.exam_code)
        ]).then(res => {
          setExamDetails(res[0].data);
          setExamRules(res[1].data);
          let maxDuration = moment.utc(res[0].data.data.endTimeMcq).subtract(6, 'h');
          let lastTime = moment(maxDuration);
          setTimer(lastTime.valueOf());
          if (lastTime.isAfter(res[0].data.data.endTimeMcq)) {
            maxDuration = moment.utc(res[0].data.data.endTimeMcq).diff(res[0].data.data.startTimeMcq, "m");
            lastTime = moment(moment.utc(res[0].data.data.endTimeMcq).add(maxDuration, "m"));
            setTimer(lastTime.valueOf());
          }
        }).catch(err => {
          window.alert("Something went wrong, please inform us");
          console.log(err);
        });

      }
    }
  }, [TOKEN, params.exam_code]);

  const doMagic = () => {

    setBtnText("Checking");
    setModalData({ title: "Checking", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
    document.getElementById('my-modal-3').checked = true;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;
    if (params.exam_variation === "mcq") {
      axios.get('/api/student/examcheckmiddleware?eId=' + params.exam_code)
        .then(({ data }) => {
          setBtnText("Checking Complete");

          setModalData({ title: "Checking Complete", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
          if (data === "assign") {
            setBtnText("Assigning questions");
            setModalData({ title: "Assigning questions", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
            axios.get('/api/student/startexam?eId=' + params.exam_code)
              .then(() => {
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
              }).catch(err => {
                console.log(err);
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                let errMsg = err?.response?.data || 'Something went wrong';
                setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
                setTimeout(() => {
                  setError(null)
                }, 3000);
                setBtnText('Show Questions');
              })
          } else if (data === "running") {
            navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
          } else {
            setModalData({ title: "Can't start now", message: "Exam has ended" });
            document.getElementById('my-modal-3').checked = true;
            setBtnText('Show Questions');
          }
        }).catch(err => {
          console.log(err);
          let errMsg = err?.response?.data || 'Something went wrong';
          setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
          setTimeout(() => {
            setError(null)
          }, 3000);
          setBtnText('Show Questions');

          setModalData(null);
          document.getElementById('my-modal-3').checked = false;
        })
    } else if (params.exam_variation === "written") {
      axios.get('/api/student/writtenexamcheckmiddleware?examId=' + params.exam_code)
        .then(({ data }) => {
          setBtnText("Checking Complete");

          setModalData({ title: "Checking Complete", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
          if (data === "assign") {
            setBtnText("Assigning questions");
            setModalData({ title: "Assigning questions", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
            axios.get('/api/student/assignquestionwritten?examId=' + params.exam_code)
              .then(() => {
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
              }).catch(err => {
                console.log(err);
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                let errMsg = err?.response?.data || 'Something went wrong';
                setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
                setTimeout(() => {
                  setError(null)
                }, 3000);
                setBtnText('Show Questions');
              })
          } else if (data === "running") {
            navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
          } else {
            setModalData({ title: "Can't start now", message: "Exam has ended" });
            document.getElementById('my-modal-3').checked = true;
            setBtnText('Show Questions');
          }
        }).catch(err => {
          console.log(err);
          let errMsg = err?.response?.data || 'Something went wrong';
          setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
          setTimeout(() => {
            setError(null)
          }, 3000);
          setBtnText('Show Questions');

          setModalData(null);
          document.getElementById('my-modal-3').checked = false;
        })
    } else if (params.exam_variation === "mcq-written") {
      axios.get('/api/student/bothexamcheckmiddleware?eId=' + params.exam_code)
        .then(({ data }) => {
          setBtnText("Checking Complete");

          setModalData({ title: "Checking Complete", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
          if (data === "Mcq assign") {
            setBtnText("Assigning MCQ questions");
            setModalData({ title: "Assigning questions", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
            axios.get('/api/student/bothstartexam?eId=' + params.exam_code)
              .then(() => {
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
              }).catch(err => {
                console.log(err);
                setModalData(null);
                document.getElementById('my-modal-3').checked = false;
                let errMsg = err?.response?.data || 'Something went wrong';
                setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
                setTimeout(() => {
                  setError(null)
                }, 3000);
                setBtnText('Show Questions');
              })
          } else if (data === "Mcq running") {
            navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
          } else {
            setModalData({ title: "Can't start now", message: "Exam has ended" });
            document.getElementById('my-modal-3').checked = true;
            setBtnText('Show Questions');
          }
        }).catch(err => {
          console.log(err);
          let errMsg = err?.response?.data || 'Something went wrong';
          setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
          setTimeout(() => {
            setError(null)
          }, 3000);
          setBtnText('Show Questions');

          setModalData(null);
          document.getElementById('my-modal-3').checked = false;
        })
    }else if(params.exam_variation === "mcq-special"){

      axios.get('/api/mcqspecialexam/examcheckmiddleware?examId=' + params.exam_code)
        .then(({ data }) => {
          setBtnText("Checking Complete");

          setModalData({ title: "Checking Complete", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
          if (data === "Mcq assign") {
            navigate(`/exam/${params.exam_code}/before-start/${params.exam_variation}`);
          } else if (data === "Mcq running") {
            navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
          } else {
            setModalData({ title: "Can't start now", message: "Exam has ended" });
            document.getElementById('my-modal-3').checked = true;
            setBtnText('Show Questions');
          }
        }).catch(err => {
          console.log(err);
          let errMsg = err?.response?.data || 'Something went wrong';
          setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
          setTimeout(() => {
            setError(null)
          }, 3000);
          setBtnText('Show Questions');

          setModalData(null);
          document.getElementById('my-modal-3').checked = false;
        })
    } 
    else {

      axios.get('/api/special/examcheckmiddleware?examId=' + params.exam_code)
        .then(({ data }) => {
          setBtnText("Checking Complete");

          setModalData({ title: "Checking Complete", showHtml: true, message: `<progress class='progress progress-error w-full'></progress>`, hideCloseBtn: true });
          if (data === "Mcq assign") {
            navigate(`/exam/${params.exam_code}/before-start/${params.exam_variation}`);
          } else if (data === "Mcq running") {
            navigate(`/exam/${params.exam_code}/ongoing/${params.exam_variation}`);
          } else {
            setModalData({ title: "Can't start now", message: "Exam has ended" });
            document.getElementById('my-modal-3').checked = true;
            setBtnText('Show Questions');
          }
        }).catch(err => {
          console.log(err);
          let errMsg = err?.response?.data || 'Something went wrong';
          setError({ message: errMsg, cssClass: 'alert-error text-white bg-color-one w-[260px]', position: 'center', alignment: 'middle' });
          setTimeout(() => {
            setError(null)
          }, 3000);
          setBtnText('Show Questions');

          setModalData(null);
          document.getElementById('my-modal-3').checked = false;
        })
    }
  }

  return (
    <>
      <div className="flex flex-row bg-white text-center mb-8 ">
        <div className='h-[68px] mx-auto'>
          <Link to="/home">
            <img src="/images/logo.png" alt="logo" className='w-64' />
          </Link>
        </div>
      </div>
      <div className="px-28 md:px-4">
        <div className="max-w-3xl container mx-auto pt-4 py-24 min-h-body">
          {/* exam content */}
          <div className="grid grid-cols-6  mt-4">
            <div className="col-start-2 md:col-start-1 col-span-4 md:col-span-6 ">
          {timer > 0 && <div className="bg-white sticky top-0 z-50">
            <CountDownTwo date={timer} completedAction={doMagic}/>
          </div>}
              {params.exam_variation === "special" && (<Suspense fallback={null}>
                {examDetails && (<ExamInfoDetails examInfos={examDetails} variationType="special" page="rules" />)}
              </Suspense>)}
              {/* user input box */}
              <div className="border border-color-six mt-4 px-4 py-8  md:py-6 rounded-md bg-white">
                <Suspense fallback={null}>
                  <Toast {...error} />
                </Suspense>
                {/* exam rules */}
                <div className="border border-color-four p-2 rounded-md">
                  <div className="text-center text-xl text-color-one mb-4">
                    <span className="uppercase block font-bold">
                      Rules And Regulations
                    </span>
                    <span className="uppercase font-bold">(Exam Day)</span>
                  </div>
                  {
                    examRules && (<img src={`${process.env.REACT_APP_FILES_HOST}/${examRules?.ruleILink}`} alt="rules" />)
                  }
                </div>
                <div className="block">
                  {btnText === "Show Questions" ? (<button type="button" className="w-full text-center btn btn-hover border-0 text-white rounded-md py-4 mt-6 mb-4" onClick={doMagic}>{btnText} <span className='btn-hover_icon'><RightArrow /></span></button>) :
                    (<button type="button" className="w-full text-center btn btn-hover border-0 text-white rounded-md py-4 mt-6 mb-4" disabled="disabled" onClick={doMagic}>{btnText} <span className='btn-hover_icon'><RightArrow /></span></button>)}

                </div>
                <BackButton
                  title="Back to exam page"
                  url={`/exam/${params.exam_code}/before-start/${params.exam_variation}`}
                  icon={backIcon}
                />
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={null}>
          <Modal {...modalData} />
        </Suspense>
      </div>
    </>
  );
};

export default ExamRules;
