import React, { useEffect, useState } from "react";
import axios from "../utils/axios"
import Skeleton from 'react-loading-skeleton'
import authChecker from "../utils/authChecker"
import { useParams } from "react-router-dom";
import v1 from "../assets/img/icons/tasksquare.svg";
import v2 from "../assets/img/icons/eye.svg";
import v3 from "../assets/img/icons/rulerpen.svg";
import Pagination from "../components/common/Pagination";
const types = {
  daily: 1,
  weekly: 2,
  monthly: 3,
};
const ExamBySubject = () => {
  const params = useParams();

  const [TOKEN, setTOKEN] = useState(null);
  const [pageCount, setPageCount] = useState(20);
  const [tableData, setTableData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [paginationData, setPaginationData] = useState(null);

  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
  });
  useEffect(() => {
    if (TOKEN) {
      authChecker();
      getExamBySubject(activeTab);
    }
  }, [TOKEN, params]);


  const handlePageClick = (event) => {
    let clickedPage = event.selected + 1;
    if (event.selected > 1) {
      getExamBySubject(clickedPage, activeTab);
    } else {
      getExamBySubject(1, activeTab);
    }
  };

  const getExamBySubject = (page=1,type=1) => {
    setActiveTab(type)
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
    axios.get('/api/exam/getexambysubject?subjectId=' + params.sId + '&variation=' + types[params.variation]+'&type='+type+'&page='+page)
      .then(({ data }) => {
        setTableData(data.examPage);
        setPaginationData(data.paginateData);
      }).catch(err => {
        console.log(err);
        setTableData([]);
        setPaginationData(null);
      });
  }

  return (
    <div className="px-28 md:px-4">
      <div className="container mx-auto">
        {/* history table */}
        <div className="overflow-x-auto pt-1 pb-8">
          <div className="tabs bg-transparent rounded-t-lg mt-4 mx-auto">
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 1 ? 'tab-active' : ''}`} onClick={() => getExamBySubject(1,1)}>MCQ</a>
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 2 ? 'tab-active' : ''}`} onClick={() => getExamBySubject(1,2)}>Written</a>
            <a className={`tab tab-lg my-tabs !px-8 tab-lifted ${activeTab == 3 ? 'tab-active' : ''}`} onClick={() => getExamBySubject(1,3)}>Both</a>
          </div>
          <table className="overflow-x-scroll table-fixed w-full customTable !mt-0">
            {/* head */}
            <thead>
              <tr className="text-center">
                <th className="py-5 min-w-full w-[80px]">Sl No.</th>
                <th className="min-w-full w-[160px]">Date</th>
                <th className="min-w-full w-[160px]">Title</th>
                <th className="min-w-full w-[160px]">Subject</th>
                <th className="min-w-full w-[160px]">D/W/M</th>
                <th className="min-w-full w-[160px]">Exam Type</th>
              </tr>
            </thead>
            <tbody>
              {tableData ?
                tableData?.exam?.map((data, index) => (
                  <tr
                    key={index}
                    className="even:bg-table-row-even odd:bg-table-row-odd text-center "
                  >
                    <th className="py-5">{index + 1}</th>
                    <td>{data.startTime}</td>
                    <td>{data.name}</td>
                    <td>{data.subjectName}</td>
                    <td>{data.examVariation}</td>
                    <td>{data.examType}</td>
                  </tr>
                )) : (<tr><td colSpan={6}><Skeleton count={5} height={64} className="my-2"></Skeleton></td></tr>)}
            </tbody>
          </table>
        </div>
        {/* react paginate */}
        <div className="pb-8">
          {paginationData && (<Pagination pageCount={paginationData.totalPages} currentPage={paginationData.currentPage} handlePageClick={(e) => handlePageClick(e)} />)}
        </div>
      </div>
    </div>
  );
};

export default ExamBySubject;
