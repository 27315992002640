import { useState, useEffect, Suspense, lazy } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import axios from '../../utils/axios'

import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import CountDownTwo from '../../components/common/CountDownTwo'
import authChecker from '../../utils/authChecker'
import RightArrow from '../../components/common/svg/RightArrow'
import ResultSummeryMcqCombined from '../../components/common/v2/ResultSummeryMcqCombined'
import QuestionWithSolutionMcqRetake from '../../components/common/v2/QuestionWithSolutionMcqRetake'
import toast from 'react-hot-toast'
import QuestionSpecialRetake from '../../components/common/v2/QuestionSpecialRetake'

const MissedSpecialRetake = () => {
  const [homeUrl, setHomeUrl] = useState('/')
  const params = useParams()
  const [TOKEN, setTOKEN] = useState(null)
  const [timer, setTimer] = useState(0)
  const [examData, setExamData] = useState([])
  const [runningData, setRunningData] = useState([])
  const [examVariation, setExamVariation] = useState('mcq')
  const [examResult, setExamResult] = useState(null)
  const [resultPage, setResultPage] = useState(false)
  const [optionCount, setOptionCount] = useState(-1)

  const navigate = useNavigate()

  // const [two, setTwo] = useState(null)
  // const [examDetails, setExamDetails] = useState()
  // const [error, setError] = useState(null)
  // const [result, setResult] = useState(null)
  // const [selectedSubjects, setSelectedSubjects] = useState([])

  useEffect(() => {
    setTOKEN(localStorage.getItem('STDNTTKN'))
  }, [])
  useEffect(() => {
    if (localStorage.getItem('STDNTTKN')) {
      setHomeUrl('/home')
    }
  }, [])

  useEffect(() => {
    let maxDuration, lastTime
    authChecker()
    if (TOKEN) {
      console.log("ekhonkar check",timer);
      console.log('checked')
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
      const getLocalData = JSON.parse(localStorage.getItem('specialReakes'))
        let curData = getLocalData[0].data
          setExamVariation('mcq')
          setExamData(curData.allData)
          setRunningData(curData.runningData)
          setOptionCount(curData.allData.optionCount)
          // //console.log(curData.data);
          maxDuration = moment
            .utc(curData.allData.data.endTimeMcq)
            .subtract(6, 'h')
          lastTime = moment(maxDuration)
          
          setTimer(lastTime.valueOf())
        window.scrollTo(0,0)
      //console.log(isExist)
    
    }
  }, [TOKEN, params.exam_code, navigate])

  const handleSubmitMcqSpecial = () => {
    let objDetails = {}
    const getLocalData = JSON.parse(localStorage.getItem('specialReakes'))
    let subjectDetails = []
    let examMarks, totalObtainedMarks =0 , negativeMarking = 0.25
    for (let i = 0; i < getLocalData.length; i++) {
      if (getLocalData[i].id === params.exam_code) {
        // console.log("22s",getLocalData[i]);
        subjectDetails = getLocalData[i].data.runningData
        examMarks = getLocalData[i].data.allData.examTotalMarks
        // negativeMarking = parseFloat(getLocalData[i].data.allData.negativeMarking)
        // totalObtainedMarks = getLocalData[i].data.allData.totalMarks
        getLocalData[i].status = 0
        break
      }
    }
    // console.log("nega:",subjectDetails[1].answeredOptions,subjectDetails[1].questions)

    for (let i = 0; i < subjectDetails.length; i++) {
      let marks = 0
      // console.log(subjectDetails[i])
      // objDetails[i].marksMcqPerSub  = 0 
      // for (let j = 0; j < subjectDetails[i].answeredOptions.length; j++) {
      //   if (subjectDetails[i].answeredOptions[j] !== -1) {
      //     if (
      //       subjectDetails[i].answeredOptions[j] ===
      //       subjectDetails[i].questions[j].correctOption
      //     ) {
      //       marks++
      //     } else {
      //       marks = marks - negativeMarking
      //     }
      //   }
      //   subjectDetails[i].marksMcqPerSub = marks
      //   // totalObtainedMarks = totalObtainedMarks + marks
      // }
      for (let j = 0; j < subjectDetails[i].questions.length; j++) {
        for (let j = 0; j < subjectDetails[i].questions.length; j++) {
          subjectDetails[i].questions[j].answeredOption =
            subjectDetails[i].answeredOptions[j]
        }
          if(subjectDetails[i].answeredOptions[j] !== -1){
            if(subjectDetails[i].answeredOptions[j]===subjectDetails[i].questions[j].correctOption){
              marks++ ;
            }else{
              // console.log("ayy ayy")
              marks = marks -  negativeMarking ;
            }
          }
      }
      subjectDetails[i].marksMcqPerSub = marks ;
      totalObtainedMarks = totalObtainedMarks + marks;
    }
    objDetails.subjectDetails = subjectDetails
    objDetails.examName = ''
    objDetails.examVariation = ''
    objDetails.totalObtainedMarks = totalObtainedMarks
    objDetails.totalMarks = examMarks
    console.log(objDetails);
    // return;
    setExamResult({
      bgColor: 'none',
      result: objDetails,
      customWidth: 'max-w-4xl',
    })
    document.getElementById('my-modal-8').checked = true
    console.log('subDetails:', subjectDetails)
    setExamData(subjectDetails)
    setResultPage(true)
    localStorage.setItem('specialReakes', JSON.stringify(getLocalData))
  }
  const handleQuestionSelectMcqSpecial = (
    event,
    questionNumber,
    activeQuestion,
    subjectId,
    subjectIndex,
    answerIndex
  ) => {
    console.log('qno', questionNumber)
    const getLocalData = JSON.parse(localStorage.getItem('specialReakes'))
    let marsPerMcq
    let negativeMarking
    for (let i = 0; i < getLocalData.length; i++) {
      if (getLocalData[i].id === params.exam_code) {
        // //console.log("caca",getLocalData[i]);
        marsPerMcq = parseFloat(
          getLocalData[i].data.allData.marksPerMcq
        ).toFixed(2)
        negativeMarking = parseFloat(
          getLocalData[i].data.allData.negativeMarking
        ).toFixed(2)
      }
    }
    let all = runningData
    let markAdder = 0
    all[subjectIndex].answeredOptions[questionNumber - 1] = parseInt(
      event.target.value
    )
    if (
      parseInt(event.target.value) ===
      parseInt(all[subjectIndex].questions[questionNumber - 1].correctOption)
    ) {
      markAdder = parseFloat(marsPerMcq)
      all[subjectIndex].marksMcqPerSub = parseFloat(
        parseFloat(all[subjectIndex].marksMcqPerSub) + marsPerMcq
      ).toFixed(2)
    } else {
      markAdder = parseFloat(negativeMarking)
      all[subjectIndex].marksMcqPerSub = parseFloat(
        parseFloat(all[subjectIndex].marksMcqPerSub) + markAdder
      ).toFixed(2)
    }
    for (let i = 0; i < getLocalData.length; i++) {
      if (getLocalData[i].id === params.exam_code) {
        //console.log("cc",getLocalData[i])
        getLocalData[i].data.allData.totalMarks = parseFloat(
          parseFloat(getLocalData[i].data.allData.totalMarks) + markAdder
        ).toFixed(2)
        getLocalData[i].data.runningData = all
      }
    }
    setRunningData([...all])
    localStorage.setItem('specialReakes', JSON.stringify(getLocalData))
    //console.log(all[subjectIndex]);
    //console.log(runningData[subjectIndex])
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
  }

  const handleCompleteExam = () => {
    const getLocalData = JSON.parse(localStorage.getItem('specialReakes'))
    const curData = getLocalData.filter((data) => data.id !== params.exam_code)
    localStorage.setItem('specialReakes', JSON.stringify(curData))
    navigate('/history')
  }

  return (
    <div className="px-1 py-1 lg:px-5 lg:py-6">
      <div className="flex flex-row bg-white text-center mb-8">
        <div className="h-[68px] mx-auto">
          <Link to={homeUrl}>
            <img src="/images/logo.png" alt="logo" className="w-64" />
          </Link>
        </div>
      </div>
      {examData && resultPage === false && timer > 0 && (
        <div className="bg-white sticky top-0 z-50">
          <CountDownTwo date={timer} completedAction={handleSubmitMcqSpecial} />
        </div>
      )}
      {runningData &&
        resultPage === false &&
        runningData.map((subject, index) => (
          <>
            <h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white rounded-t-md uppercase">
              {subject.subjectName}
            </h2>
            <div
              className="bg-white px-6 pt-6 border-4 rounded-b-xl border-orange-600 mb-4"
              key={index}
            >
              {subject.questions.map((question, idx) => (
                <QuestionSpecialRetake
                  question={question}
                  subjectId={subject.subjectId}
                  subjectIndex={index}
                  index={idx}
                  optionCount={optionCount}
                  answeredIndex={subject.answeredOptions[idx]}
                  key={idx}
                  handleSubmitMcqSpecial={handleQuestionSelectMcqSpecial}
                />
              ))}
            </div>
          </>
        ))}
      {resultPage === false && (
        <div className="text-center my-4 max-w-sm mx-auto">
          <button
            className="btn-hover text-white pr-2 py-3 rounded-md"
            onClick={handleSubmitMcqSpecial}
          >
            Submit Answer
            <span className="btn-hover_icon">
              <RightArrow />
            </span>
          </button>
        </div>
      )}
      {resultPage ? (
        <>
          {examData.length > 0 &&
            examData.map((subject, index) => (
              <div
                className="rounded-lg bg-white my-8 border-2 border-orange-600"
                key={index}
              >
                {index < 4 && (
                  <>
                    <h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white mb-8 uppercase">
                      {subject.subjectName ?? ''}
                    </h2>
                    <div className="px-6 md:px-4 py-6 md:py-4">
                      {subject.questions.map((question, idx) => (
                        <QuestionWithSolutionMcqRetake
                          question={question}
                          counter={++idx}
                          key={idx}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
          <div className="text-center my-4 max-w-sm mx-auto">
            <button
              className="btn-hover text-white pr-2 py-3 rounded-md"
              onClick={handleCompleteExam}
            >
              Complete Exam
              <span className="btn-hover_icon">
                <RightArrow />
              </span>
            </button>
          </div>
        </>
      ) : (
        <Skeleton count={5} height={10}></Skeleton>
      )}
      <Suspense fallback={null}>
        <ResultSummeryMcqCombined {...examResult} />
      </Suspense>
    </div>
  )
}

export default MissedSpecialRetake
