import { Suspense, lazy, useEffect, useState, useRef } from 'react'
import axios from '../../utils/axios'
import { useParams, useNavigate, Link } from 'react-router-dom'
import BackButton from '../../components/common/BackButton'
import RightArrow from '../../components/common/svg/RightArrow'
import backIcon from '../../assets/img/icons/leftArrow.png'
import authChecker from '../../utils/authChecker'

import Skeleton from 'react-loading-skeleton'

const Modal = lazy(() => import('../../components/common/Modal'))

//start exam => updtestudent
const BeforeStartRetake = (props) => {
  let history = useNavigate()
  const params = useParams()
  const variation = 'mcq-special'
  const [TOKEN, setTOKEN] = useState(null)
  const [examDetails, setExamDetails] = useState()
  const [optionalSubjects, setOptionalSubjects] = useState()
  const [combinations, setCombinations] = useState()
  const [selectedSpecial, setSelectedSpecial] = useState({
    subjectId: null,
    courseId: [],
  })
  const [specialDisabled, setSpecialDisabled] = useState('disabled')
  const [checkNumber, setCheckNumber] = useState('disabled')
  const [error, setError] = useState(null)
  const [examPage,setExamPage] = useState(false)
  const subject = useRef(null)
  useEffect(() => {
    setTOKEN(localStorage.getItem('STDNTTKN'))
  })
  useEffect(() => {
    if (TOKEN) {
      authChecker()
      console.log('ei page')
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
      axios
        .get('/api/special/getoptionalsubject?examId=' + params.exam_code)
        .then(({ data }) => {
          setOptionalSubjects(data)
          axios
            .get('/api/special/showspecialexambyid?examId=' + params.exam_code)
            .then((examData) => {
              // console.log(examData.data)
              setExamDetails(examData.data)
            })
        })
        .catch((err) => {
          window.alert('Something went wrong, please inform us')
          console.log(err)
        })
    }
  }, [TOKEN, params.exam_code, variation, history])

  useEffect(() => {
    if (variation === 'special') {
      if (selectedSpecial.subjectId && selectedSpecial.courseId.length > 0) {
        setSpecialDisabled('')
      }
    }
  }, [selectedSpecial, variation])
  
  const onSubmitHandler = (e) => {
    console.log('hello from here')
    e.preventDefault()
    console.log('check', selectedSpecial)
    // return
    axios
      .get(
        `/api/special/getexamsubjectsretake?examId=${params.exam_code}&subjectId1=${selectedSpecial.courseId[0]}&subjectId2=${selectedSpecial.courseId[1]}&subjectId3=${selectedSpecial.courseId[2]}&subjectId4=${selectedSpecial.courseId[3]}`
      )
      .then(({ data }) => {
        console.log(data);
        const newData = {
            id: params.exam_code,
            startTimeMcq: data.allData.data.startTimeMcq,
            endTimeMcq: data.allData.data.endTimeMcq,
            data: data,
            status: 1,
            optionCount:data.allData.optionCount,
          }
          let allData= [];
          allData.push(newData);
          localStorage.setItem('specialReakes', JSON.stringify(allData))
          history(`/exam/${params.exam_code}/special-missed`)
      })
      
  }
  const getCombination = async (e) => {
    setCombinations(null)
    const opsId = e.currentTarget.value
    axios
      .get(
        `/api/special/getcombination?examId=${params.exam_code}&optionalSubjectId=${opsId}`
      )
      .then(async ({ data }) => {
        // console.log(data)
        let processedData = []
        for await (const combo of data) {
          let x = { name: '', ids: '' }
          combo.map((d, index) => {
            x.name += d.name
            x.ids += d._id
            if (index < examDetails.noOfExamSubject - 1) {
              x.name += ' + '
              x.ids += '#'
            }
            return x
          })
          processedData.push(x)
        }
        setCombinations(processedData)
        setSelectedSpecial({ subjectId: opsId, courseId: [] })
      })
      .catch((err) => {
        window.alert('Something went wrong, please inform us')
        console.log(err)
      })
  }
  return (
    <>
      <div className="flex flex-row bg-white text-center mb-8">
        <div className="h-[68px] mx-auto">
          <Link to="/home">
            <img src="/images/logo.png" alt="logo" className="w-64" />
          </Link>
        </div>
      </div>
      <div className="px-4">
        <div className="max-w-3xl container mx-auto pt-8 pb-24">
          {/* exam content */}
          <div className="grid grid-cols-6 gap-2 mt-4">
            <div className="col-start-2 md:col-start-1 col-span-4 md:col-span-6 min-h-body">
              {optionalSubjects && (
                <div className="border border-color-six mt-4 px-12 md:px-4 py-8 mb-4 md:py-4 rounded-lg bg-white">
                  <div className="form-control w-full formEl">
                    <label className="label formLabel !cursor-default self-start text-white relative w-full">
                      <span className="label__icon shadow-[4px_2px_6px_0px_rgba(0,0,0,0.5)]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-12 h-12 md:w-8 md:h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                          />
                        </svg>
                      </span>
                      <span className="label__text">Main Subjects</span>
                    </label>
                    <div className="input__container show" ref={subject}>
                      {/* <div> */}

                      <div className="flex flex-row  justify-around">
                        {optionalSubjects.map((sub, index) => (
                          <div className="border basis-1/2" key={index}>
                            <label className="label cursor-pointer px-1">
                              <span className="label-text text-orange-500 mr-1">
                                {sub.name}
                              </span>
                              <input
                                type="radio"
                                defaultValue={sub._id}
                                className="radio h-4 w-4 checked:bg-orange-500"
                                name="subject"
                                onClick={(e) => getCombination(e)}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                  {combinations ? (
                    <div className="form-control w-full formEl">
                      <label className="label formLabel self-start text-white relative w-full">
                        <span className="label__icon shadow-[4px_2px_6px_0px_rgba(0,0,0,0.5)]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-12 h-12 md:w-8 md:h-8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </span>
                        <span className="label__text">Combinations</span>
                      </label>
                      <div className="exam_combinations">
                        {combinations.map((combo, index) => (
                          <div className="border basis-1" key={index}>
                            <label className="label cursor-pointer px-6">
                              <span className="label-text text-orange-500 mr-4">
                                {combo.name}
                              </span>
                              <input
                                type="radio"
                                defaultValue={combo.ids}
                                className="radio h-4 w-4 checked:bg-orange-500"
                                name="combination"
                                onClick={(e) =>
                                  setSelectedSpecial({
                                    ...selectedSpecial,
                                    courseId: e.target.value.split('#'),
                                  })
                                }
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mx-8">
                      <Skeleton
                        count={1}
                        height={8}
                        baseColor="#FF4B0025"
                        highlightColor="#FF4B00"
                        className="mt-4"
                      ></Skeleton>
                    </div>
                  )}
                </div>
              )}
              {/* user input box */}
              {examDetails && (
                <div className="border border-color-six mt-4 px-12 md:px-4 py-8 mb-4 md:py-4 rounded-lg bg-white">
                  <form onSubmit={onSubmitHandler}>
                    <div className="form-control mb-4">
                      <button
                        type="submit"
                        className="btn-hover border-0 rounded-md py-3 pr-1 my-4 disabled:bg-color-five"
                        //   disabled={checkNumber || specialDisabled}
                      >
                        Continue to Exam
                        <span className="btn-hover_icon">
                          <RightArrow />
                        </span>
                      </button>
                    </div>
                  </form>

                  <BackButton
                    title="Back to home page"
                    url="/"
                    icon={backIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <Suspense fallback={null}>
          <Modal {...error} />
        </Suspense>
      </div>
    </>
  )
}

export default BeforeStartRetake
