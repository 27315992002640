import React, { Fragment, useState, useEffect } from "react";
import axios from "../utils/axios";

import CountDownExam from "../components/home/CountDownExam";
import ExamCardTwo from "../components/ExamCardTwo";
import Skeleton from 'react-loading-skeleton'

import authChecker from "../utils/authChecker"

const moment = require('moment');
const ExamList = React.lazy(() => import("../components/ExamList"));
const NoExams = React.lazy(() => import("../components/home/NoExams"));
const Home = (props) => {

  const [TOKEN, setTOKEN] = useState(null);
  const [apiCallDone, setApiCallDone] = useState(false);
  const [homePage, setHomePage] = useState({
    top: null, bottom: null
  });
  useEffect(() => {
    if (!localStorage.getItem("STDNTTKN")) {
      window.location.href = "/";
    }
    setTOKEN(localStorage.getItem("STDNTTKN"));
  }, []);
  useEffect(() => {
    if (TOKEN) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;
      Promise.all([
        axios.get('/api/home/gethomepage?section=top'),
        axios.get('/api/home/gethomepage?section=bottom')
      ]).then(res => {
        // console.log(res[0].data);
        setHomePage({ top: res[0].data, bottom: res[1].data });
        setApiCallDone(true);
      }).catch(err => {
        console.log(err);
      });

      return () => {
        authChecker();
      }
    }
  }, [TOKEN]);

  return (
    <Fragment>
      <div className="px-28 md:px-4">
        <div className="container mx-auto py-6">
          {apiCallDone ? (
            <div className="grid grid-cols-12 md:block gap-4">
              <div className="col-span-6 bg-white rounded-xl py-6">
                <h2 className="text-4xl uppercase indent-1 text-title-2 font-semibold text-center mb-3">Upcoming Exam</h2>
                {
                  homePage?.top?.comingExam.length > 0 ? (
                    <div className="grid gap-4 grid-cols-2">
                      {homePage.top.comingExam.map((data, index) => (
                        <div className="col-span-2 rounded-lg px-5 py-0" key={index}>
                          <CountDownExam
                            id={data._id}
                            title={data.name}
                            img={data.iLink}
                            date={moment.utc(data.startTime).subtract(6,'h')}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-white px-6 pt-6 pb-4 rounded-lg mb-4">
                      <React.Suspense fallback={<Skeleton></Skeleton>}>
                        <NoExams message="No upcoming exams at the moment"></NoExams>
                      </React.Suspense>
                    </div>
                  )
                }
              </div>
              <div className="col-span-6 bg-white rounded-lg py-5">
                <h2 className="text-4xl uppercase indent-1 text-title-2 font-semibold text-center mb-3">Today's Live Exam</h2>
                {
                  homePage?.top?.runningExam.length > 0 ? (
                    <div className="grid gap-4 grid-cols-2">
                      {homePage.top.runningExam.map((data, index) => (
                        <div className="col-span-2 rounded-lg px-5 py-0" key={index}>
                          <ExamCardTwo exam={data} variation={props.variation[data.examVariation]}/>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-white px-6 pt-6 pb-4 rounded-lg mb-4">
                      <React.Suspense fallback={null}>
                        <NoExams message="No running exams at the moment"></NoExams>
                      </React.Suspense>
                    </div>
                  )
                }
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-12 md:block gap-4">
              <div className="col-span-6 ">
                <Skeleton count={2} height={192} baseColor="#FF4B0015" highlightColor="#FF4B0015" className="my-2"></Skeleton>
              </div>
              <div className="col-span-6 ">
                <Skeleton count={2} height={192} baseColor="#FF4B0015" highlightColor="#FF4B0015" className="my-2"></Skeleton>
              </div>
            </div>
          )}

        </div>
      </div>
      <div className="px-28 md:px-4">
        <div className="container mx-auto py-12">
          {apiCallDone ? (
            <div>
              {homePage?.bottom?.daily.length > 0 && (<React.Suspense fallback={null}><ExamList title="Daily Exam" exam={homePage?.bottom?.daily.slice(0,4)} exam_slug="daily" /></React.Suspense>)}
              {homePage?.bottom?.weekly.length > 0 && (<React.Suspense fallback={null}><ExamList title="Weekly Exam" exam={homePage?.bottom?.weekly.slice(0,4)} exam_slug="weekly" /></React.Suspense>)}
              {homePage?.bottom?.monthly.length > 0 && (<React.Suspense fallback={null}><ExamList title="Monthly Exam" exam={homePage?.bottom?.monthly.slice(0,4)} exam_slug="monthly" /></React.Suspense>)}
            </div>
          ) : (
            <div className="bg-white relative rounded-xl border border-border-color px-5 py-8 lg:mb-10 mb-24">
              <div className="grid lg:grid-cols-5 md:grid-cols-1 gap-8 md:gap-2 px-8 md:px-4">
                <Skeleton count={3} height={150} baseColor="#FF4B0015" highlightColor="#FF4B0015" duration={2} className="my-2"></Skeleton>
                <Skeleton count={3} height={150} baseColor="#FF4B0015" highlightColor="#FF4B0015" duration={2} className="my-2"></Skeleton>
                <Skeleton count={3} height={150} baseColor="#FF4B0015" highlightColor="#FF4B0015" duration={2} className="my-2"></Skeleton>
                <Skeleton count={3} height={150} baseColor="#FF4B0015" highlightColor="#FF4B0015" duration={2} className="my-2"></Skeleton>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Home;
