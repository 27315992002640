import React, { useEffect, useState } from 'react'
import authChecker from '../utils/authChecker'
import { Link } from 'react-router-dom'
import axios from '../utils/axios'
import Skeleton from 'react-loading-skeleton'
import banner from '../assets/img/student-banner.png'

const Profile = () => {
  const [TOKEN, setTOKEN] = useState(null)
  // console.log(userId);
  const [isLoading, setIsLoading] = useState(false)
  const [studentDetails, setStudentDetails] = useState({})

  useEffect(() => {
    setIsLoading(true)
    if (TOKEN) {
      authChecker()
    }
    axios.get(`/api/student/getstudentinfobyid`).then(({ data }) => {
      console.log(data)
      setStudentDetails(data)
      setIsLoading(false)
    })
  }, [TOKEN])

  return (
    <div className="px-28 md:px-4">
      {isLoading && (
        <Skeleton
          count={20}
          height={8}
          baseColor="#FF4B0025"
          highlightColor="#FF4B00"
          className="mt-8"
        ></Skeleton>
      )}
      {/* <div className="p-8  shadow mt-24">
        {' '}
        <div className="grid grid-cols-1 md:grid-cols-3">
          {' '}
          <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
            {' '}
          </div>{' '}
          <div className="relative">
            {' '}
            <div className="w-48 h-48 bg-blend-darken mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-24 w-24"
                viewBox="0 0 20 20"
                fill="#F54B03"
              >
                {' '}
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>{' '}
            </div>{' '}
          </div>{' '}
        </div>{' '}
        <div className="mt-20 text-center border-b pb-12">
          {' '}
          <h1 className="text-4xl font-medium text-gray-700">
            Jessica Jones, <span className="font-light text-gray-500">27</span>
          </h1>{' '}
          <p className="font-light text-gray-600 mt-3">Bucharest, Romania</p>{' '}
          <p className="mt-8 text-gray-500">
            Solution Manager - Creative Tim Officer
          </p>{' '}
          <p className="mt-2 text-gray-500">University of Computer Science</p>{' '}
        </div>{' '}
        <div className="mt-12 flex flex-col justify-center">
          {' '}
          <p className="text-gray-600 text-center font-light lg:px-16">
            An artist of considerable range, Ryan — the name taken by
            Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and
            records all of his own music, giving it a warm, intimate feel with a
            solid groove structure. An artist of considerable range.
          </p>{' '}
          <button className="text-indigo-500 py-2 px-4  font-medium mt-4">
            {' '}
            Show more
          </button>{' '}
        </div>
      </div> */}
      <section className="flex flex-col justify-center  items-center  text-color-one leading-normal py-10 ">
        <div className="w-full lg:w-3/5 bg-white p-0 lg:p-2">
          <div
            className="w-full  rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl marker: mx-1 lg:mx-0"
            style={{
              backgroundImage: `url(${banner})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center center',
            }}
          >
            <div className="w-48 h-48"></div>
          </div>
        </div>

        <div className="bg-white w-full lg:w-3/5 mt-4  ">
          <div
            id="profile"
            className="w-full rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl  marker: mx-1 lg:mx-0 "
          >
            <div className="p-4  text-center ">
              <div className="mx-auto lg:mx-0 w-full pt-3 border-b-2 border-color-one opacity-25"></div>

              <div className="grid gird-cols-1 lg:grid-cols-3 gap-4">
                <div className='flex justify-center items-center'>
                <div className="w-32 h-32 lg:w-48 lg:h-48 bg-gray-400 mx-auto rounded-full shadow-2xl my-1 flex items-center justify-center text-indigo-500">
                  {studentDetails.displayPicture === null ? (
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-24 w-24 "
                        viewBox="0 0 20 20"
                        fill="#F54B03"
                      >
                        {' '}
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <img
                        className="w-48 h-48 rounded-full"
                        src={`${process.env.REACT_APP_FILES_HOST}/${studentDetails.displayPicture}`}
                        alt="pp"
                      />
                    </div>
                  )}
                </div>
                </div>
                <div className="lg:col-span-2 grid grid-cols-1  gap-4 mt-4">
                  <div>
                    <p className="text-sm lg:text-xl flex justify-left items-center text-color-one">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.5 3.75a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6.75a3 3 0 0 0-3-3h-15Zm4.125 3a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.873 8.703a4.126 4.126 0 0 1 7.746 0 .75.75 0 0 1-.351.92 7.47 7.47 0 0 1-3.522.877 7.47 7.47 0 0 1-3.522-.877.75.75 0 0 1-.351-.92ZM15 8.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15ZM14.25 12a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>

                      <span className="font-semibold  mr-2 mr-2">Name: </span>
                      <span className="font-semibold">
                        {studentDetails.name}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="flex justify-left items-center text-sm lg:text-xl text-color-one">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path d="M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z" />
                          <path d="M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z" />
                          <path d="M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z" />
                        </svg>
                      </span>
                      <span className="font-semibold  mr-2 mr-2 ">
                        Institution:{''}
                      </span>
                      <span className="font-semibold">
                        {studentDetails.institution === null
                          ? 'Not Added Yet'
                          : '   ' + studentDetails.institution}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="flex justify-left items-center text-sm lg:text-xl text-color-one">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span className="font-semibold  mr-2 mr-2">
                        Contact No:{' '}
                      </span>
                      <span className="font-semibold">
                        {studentDetails.mobileNo === null
                          ? 'Not Added Yet'
                          : studentDetails.mobileNo}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-left flex justify-left items-center text-sm lg:text-xl text-color-one">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path d="M18.75 12.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM12 6a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 6ZM12 18a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 18ZM3.75 6.75h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM5.25 18.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5ZM3 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3 12ZM9 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM12.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                        </svg>
                      </span>
                      <span className="font-semibold mr-2">Roll No: </span>
                      <span className="font-semibold">
                        {studentDetails.curriculumRoll === null
                          ? 'Not Added Yet'
                          : studentDetails.curriculumRoll}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-left text-sm lg:text-xl flex justify-left items-center text-color-one">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.5 3.75a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6.75a3 3 0 0 0-3-3h-15Zm4.125 3a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.873 8.703a4.126 4.126 0 0 1 7.746 0 .75.75 0 0 1-.351.92 7.47 7.47 0 0 1-3.522.877 7.47 7.47 0 0 1-3.522-.877.75.75 0 0 1-.351-.92ZM15 8.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15ZM14.25 12a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span className="font-semibold  mr-2">
                        Registration No:{' '}
                      </span>
                      <span className="font-semibold">
                        {studentDetails.regNo}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="mx-auto lg:mx-0 w-full pt-3 border-b-2 border-color-one opacity-25"></div>
              <div className="pt-12 ">
                <Link
                  to={`/${studentDetails._id}/edit-profile`}
                  className="mr-4"
                >
                  <button className="bg-color-one hover:bg-gray-700 text-white font-semibold  mr-2 py-2 px-4 rounded-full zoom">
                    Edit Profile
                  </button>
                </Link>
                <Link to="/change-password">
                  <button className="bg-color-one hover:bg-gray-700 text-white font-semibold  mr-2 py-2 px-4 rounded-full zoom">
                    Change Passowrd
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
          <div className="w-full lg:w-2/5">
            <img
              src="https://res.cloudinary.com/codelikeagirl29/image/upload/v1694590568/dev-branding/Untitled_design_xcfmmz.png"
              className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block hover:scale-50 zoom"
              alt="displayPicture"
            />
          </div>

          <div className="absolute top-0 right-0 h-12 w-18 p-4">
            <button className="js-change-theme focus:outline-none">🌙</button>
          </div>
        </div> */}
      </section>
    </div>
  )
}

export default Profile
